import { axiosDefaultInstance } from '@/http/default'

import type Customer from '@/model/Customer'
import type { CustomerRequest } from '@/model/Customer'
import { useProfileStore } from '@/store/profile.module'
import type { AxiosInstance } from 'axios'
import handleError from './util/ErrorHandler'
import type { ServiceError } from './util/ServiceError'

class CustomerService {
  private axiosInstance: AxiosInstance
  private storeProfile: any

  constructor() {
    this.axiosInstance = axiosDefaultInstance
    // this.storeProfile = useProfileStore()
  }

  async findAll(): Promise<Customer[] | ServiceError> {
    this.storeProfile = useProfileStore()

    const params: string[][] = []
    const searchParams = new URLSearchParams(params)

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<Customer[]>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/customer`,
        { params: searchParams }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async findById(id: string): Promise<Customer | ServiceError> {
    this.storeProfile = useProfileStore()

    const params: string[][] = []
    const searchParams = new URLSearchParams(params)

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<Customer>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/customer/${id}`,
        { params: searchParams }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async createCustomer(customer: CustomerRequest): Promise<Customer | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.post<Customer>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/customer`,
        customer
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async updateCustomer(customer: CustomerRequest): Promise<Customer | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.put<Customer>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/customer/${customer.id}`,
        customer
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteCustomer(id: string): Promise<Customer | ServiceError> {
    this.storeProfile = useProfileStore()
    
    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.delete<Customer>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/customer/${id}`
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }
}

export const customerService = new CustomerService()
