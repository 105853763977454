import { createI18n } from 'vue-i18n'
import es from '../locales/es.json'
import en from '../locales/en.json'


const i18n = createI18n({
  legacy: false,
  messages: {
    'es': es,
    'en': en
  },
  locale: 'es',
  fallbackLocale: 'es',
  globalInjection: true
})

export default i18n
