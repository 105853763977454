import { axiosDefaultInstance } from '@/http/default'

import type Employee from '@/model/Employee'
import { Role, type EmployeeRequest } from '@/model/Employee'
import { useProfileStore } from '@/store/profile.module'
import type { AxiosInstance } from 'axios'
import handleError from './util/ErrorHandler'
import type { ServiceError } from './util/ServiceError'
import * as Sentry from '@sentry/vue'

class EmployeeService {
  private axiosInstance: AxiosInstance
  private storeProfile: any

  constructor() {
    this.axiosInstance = axiosDefaultInstance
    // this.storeProfile = useProfileStore()
  }

  async findMe(): Promise<Employee | ServiceError> {
    this.storeProfile = useProfileStore()

    try {
      const networkResponse = await this.axiosInstance.get<Employee>(
        `${import.meta.env.VITE_API_BASE_URL}/employee/me`
      )

      this.storeProfile.saveProfile(networkResponse.data)

      Sentry.setUser({
        id: networkResponse.data.id,
        email: networkResponse.data.email
      })

      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async findAll(): Promise<Employee[] | ServiceError> {
    this.storeProfile = useProfileStore()

    const params: string[][] = []
    const searchParams = new URLSearchParams(params)

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<Employee[]>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/employee`,
        { params: searchParams }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async createEmployee(employee: EmployeeRequest): Promise<Employee | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.post<Employee>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/employee`,
        employee
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async updateEmployee(employee: EmployeeRequest): Promise<Employee | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.put<Employee>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/employee/${employee.id}`,
        employee
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteEmployee(id: string): Promise<Employee | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.delete<Employee>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/employee/${id}`
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async findRoles(): Promise<Role[]> {
    return [Role.ADMIN, Role.DRIVER]
  }
}

export const employeeService = new EmployeeService()
