import type Company from '@/model/Company'
import type { CompanyEmployee } from '@/model/Employee'
import type Employee from '@/model/Employee'
import { defineStore } from 'pinia'

export const useProfileStore = defineStore('profile-store', {
  state: () => ({
    // authUserToken: '' as string,
    profile: undefined as Employee | undefined,
    selectedCompany: {} as CompanyEmployee 
  }),
  getters: {
    getProfile: (applicationState: any) => applicationState.profile,
    getSelectedCompany: (applicationState: any) => applicationState.selectedCompany
  },
  actions: {
    async saveProfile(employee: Employee) {
      this.profile = employee

      this.selectedCompany = employee.companies[0]
    }
  }
})
