export class ServiceError {
  constructor(messages: string[], code: string, status: number, timestamp: number) {
    this.messages = messages
    this.code = code
    this.status = status
    this.timestamp = timestamp
    Object.setPrototypeOf(this, ServiceError.prototype)
  }
  messages: string[]
  code: string
  status: number
  timestamp: number
}
