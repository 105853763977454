import { onAuthStateChanged, signInWithEmailAndPassword, signOut, type User } from 'firebase/auth'
import { auth } from '../../firebaseConfig'
import { employeeService } from './EmployeeService'
import handleError from './util/ErrorHandler'
import type { ServiceError } from './util/ServiceError'

class AuthService {
  async login(user: string, password: string): Promise<User | ServiceError> {
    return signInWithEmailAndPassword(auth, user, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        return user
        // ...
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message

        console.log('errorCode', errorCode)
        console.log('errorMessage', errorMessage)
        return handleError(error)
      })
  }

  async logout(): Promise<void> {
    await signOut(auth)
  }

  getCurrentUser(): User | null {
    return auth.currentUser
  }

  private onAuthStateChanged() {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          unsubscribe()
          resolve(user)
        },
        reject
      )
    })
  }

  async isAuthenticated(): Promise<boolean> {
    const cUser = await this.onAuthStateChanged()

    if (cUser) {
      await employeeService.findMe()
    }

    return cUser !== undefined && cUser !== null
  }
}

export const authService = new AuthService()
