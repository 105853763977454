export enum ChartType {
  DELIVERY_NOTES_PER_DAY = 'DELIVERY_NOTES_PER_DAY',
  DELIVERY_NOTES_COUNT = 'DELIVERY_NOTES_COUNT'
}

enum ResumeType {}

enum SerieName {
  High = 'high',
  Low = 'low',
  Open = 'open',
  Close = 'close',
  Median = 'median'
}

interface Serie {
  name: string
  data: number[]
  xaxis: string[]
}

interface Chart {
  type: ChartType
  series: Serie[]
}

interface Statistics {
  charts: Chart[]
}

export type { Chart, ResumeType, Serie, SerieName, Statistics }
