import { ServiceError } from './ServiceError'

async function handleError(error: any): Promise<ServiceError> {
  return new ServiceError(
    error.response?.data.messages,
    error.response?.data.code,
    error.response?.status,
    error.response?.data.timestamp
  )
}

export default handleError
