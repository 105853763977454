import axios, { type InternalAxiosRequestConfig } from 'axios'
import { auth } from '../../firebaseConfig'

const defaultConfig = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-type': 'application/json'
  }
}

const preRequestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const currentUser = auth.currentUser
  const newConfig = { ...config }
  if (newConfig.headers) {
    newConfig.headers['authorization'] = 'Bearer ' + (await currentUser?.getIdToken())
  }

  return newConfig
}

// const onRequestErrorInterceptor = async (error: AxiosError) => {
//   const authStore = useAuthStore();
//   const response = error.response;
//   const originalConfig: any = error.config;
//   if (!error) {
//     return undefined;
//   }
//   if (!response || !response.status) {
//     return Promise.reject(error);
//   }
//   if (response.status !== 401) {
//     return Promise.reject(error);
//   }
//   if (originalConfig._retry) {
//     Auth0.logout();
//     return Promise.reject(error);
//   } else {
//     await Auth0.refreshAccessToken();

//     const newConfig = { ...originalConfig };
//     newConfig.headers['authorization'] = 'Bearer ' + authStore.accessToken;
//     newConfig._retry = true;
//     return axios.request(newConfig);
//   }
// };

const axiosDefaultInstance = axios.create(defaultConfig)
axiosDefaultInstance.interceptors.request.use(preRequestInterceptor)
// axiosDefaultInstance.interceptors.response.use((r) => r, onRequestErrorInterceptor);

export { axiosDefaultInstance, preRequestInterceptor }
