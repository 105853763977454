export  interface CompanyEmployee {
  id: string
  name: string // Nombre comercial
  role: Role
}

export default interface Employee {
  id: string
  name: string
  surname: string
  email: string
  phoneNumber: string
  nif: string
  companies: CompanyEmployee[]
}

export interface EmployeeResponse {
  employees: Employee[]
}

export interface EmployeeRequest {
  id: string
  name: string
  surname: string
  email: string
  phoneNumber: string
  nif: string
  role: Role
}

export enum Role {
  ADMIN = 'ADMIN',
  DRIVER = 'DRIVER'
}
