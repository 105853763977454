
import type { Bill } from './Bill'
import type Customer from './Customer'
import type Employee from './Employee'
import type EuropeanWasteCategory from './EuropeanWasteCategory'
import type Place from './Place'
import { PriceUnit } from './Price'
import type Vehicle from './Vehicle'

export enum DeliveryNoteType {
  STANDARD = "STANDARD",
  DIGITAL = "DIGITAL",
}

export interface TripDto {
  dateTime: Date;
  amount?: number;
}

export interface Trip {
  dateTime: string;
  amount: number;
}

export interface Concept {
  id: string;
  optionId?: string;
  europeanWasteCategory?: EuropeanWasteCategory;
}

export interface PayloadDto {
  payload: string;
  unit: PriceUnit;
  quantity?: number;
  concept: Concept;
}

export interface Payload {
  id: string;
  payload: string;
  unit: PriceUnit;
  quantity: number;
  concept: Concept;
}

export interface DeliveryNote {
  id: string;
  number: string;
  date: string;
  customer?: Customer;
  customerName?: string;
  employee: Employee;
  vehicles: Vehicle[];
  origin?: Place;
  originName?: string;
  originCustomer?: Customer;
  originCustomerName?: string;
  destination?: Place;
  destinationName?: string;
  destinationCustomer?: Customer;
  destinationCustomerName?: string;
  payloads: Payload[];
  notes: string;
  trips: Trip[];
  signed?: boolean;
  type: DeliveryNoteType;
  bill: Bill;
  templateId: string;
  mandatorySign: boolean;
}

export interface DeliveryNoteResponse {
  deliveryNotes: DeliveryNote[];
}

export interface DeliveryNoteRequest {
  id: string;
  employeeId?: string;
  number: string;
  date: string;
  customerId?: string;
  customerName?: string;
  vehicleIds: string[];
  originId?: string;
  originName?: string;
  originCustomerId?: string;
  originCustomerName?: string;
  destinationId?: string;
  destinationName?: string;
  destinationCustomerId?: string;
  destinationCustomerName?: string;
  payloads: Payload[];
  notes: string;
  trips: Trip[];
  type: DeliveryNoteType;
  templateId: string;
}

export interface DeliveryNoteImage {
  name: string;
  data: Blob;
}

export interface DeliveryNoteImages {
  images: DeliveryNoteImage[];
}